import React, { useEffect, useState } from 'react';
import history from '../../../../history'

function DeleteButton({ entity, service, itemId }) {
    const [showModal, setShowModal] = React.useState(false);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)

    const handleDelete = () => {
        setLoading(true)
        service.deleteService(entity, itemId.join(',')).then(item => {
            console.log(item)
            item.error && setError(item.error)
            item.data !== null && setData(item.data)
            setLoading(false)
        })
    }

    useEffect(() => {
        if (data !== null) {
            setShowModal(false)
            document.location.reload()
        }
    }, [data])

    const loader = () => {
        if (isLoading) {
            return (
                <div>Loading...</div>
            )
        }
    }

    return (
        <>
            <button onClick={() => setShowModal(true)} className="outline-none focus:outline-none">
                <span className="material-icons text-blue-500 m-2">delete</span>
            </button>
            <>
                {showModal ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="relative w-auto my-6 mx-auto max-w-xl">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-2 border-b border-solid border-gray-200 rounded-t">
                                        <h3 className="text-lg p-3 font-semibold">
                                            Suppression d'une annonce
                                        </h3>
                                        <button
                                            className="p-2 ml-auto bg-transparent border-0 text-black opacity-75 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <span className="material-icons text-2xl block outline-none focus:outline-none">
                                                close
                                            </span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto">
                                        {loader()}
                                        {error && <div className="shadow-md bg-red-50 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                                            <p className="font-bold">Erreurs</p>
                                            {
                                                Object.values(error.message).map((message, index) => (
                                                    <p key={index}>{message}</p>
                                                ))
                                            }
                                        </div>}
                                        <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                                            Êtes vous sûr de supprimer toutes les Données concernent à cette annonce?
                                        </p>
                                    </div>
                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
                                        <button
                                            className="text-gray-500 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none"
                                            type="button"
                                            onClick={() => setShowModal(false)}
                                        >
                                            Fermer
                                        </button>
                                        <button
                                            className="text-red-500 font-bold uppercase text-sm px-6 py-3 outline-none focus:outline-none"
                                            type="button"
                                            onClick={() => handleDelete()}
                                        >
                                            Supprimer
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-40 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
            </>
        </>
    );
}

export default DeleteButton;