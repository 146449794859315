const config = {
    style: {
        defaults: {
            table: 'w-full text-left table-collapse',
            header:{
                th: 'text-sm font-semibold px-2 bg-gray-100 border-t border-gray-300',
            },
            body: {
                tr: 'h-12 border-t border-gray-300 text-sm hover:bg-gray-100',
                td: 'py-2 whitespace-no-wrap',
            }
        }
    }
}

export default config
