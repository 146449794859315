import React from 'react';

function Loading(props) {
    const pulse = (index) => (
        <figure key={index} className="animate-pulse md:flex w-full rounded-xl shadow-md bg-white p-4 md:p-0 relative">
            <picture className="w-full h-60 md:w-64 bg-gray-400 rounded-xl md:rounded-r-none relative items-center justify-center flex"></picture>
            <div className="pt-4 md:pb-4 md:px-8 text-center md:text-left flex flex-col mt-2 space-y-4">
                <div className="h-6 rounded-lg bg-gray-300"></div>
                <div className="flex-grow space-y-2">
                    <div className="h-6 rounded-lg bg-gray-300"></div>
                    <div className="h-6 rounded-lg bg-gray-300"></div>
                </div>
                <div className="md:flex md:justify-between font-medium text-gray-500 text-left">
                    <div>
                        <div className="inline-flex items-center">
                            <div className="h-6 w-28 rounded-lg bg-gray-300"></div>
                        </div>
                    </div>
                    <div>
                        <div className="inline-flex items-center md:mx-6">
                            <div className="h-6 w-28 rounded-lg bg-gray-300"></div>
                        </div>
                    </div>
                    <div>
                        <div className="inline-flex items-center">
                            <div className="h-6 w-28 rounded-lg bg-gray-300"></div>
                        </div>
                    </div>
                </div>
            </div>
            <span className="bg-gray-300 h-6 w-12 rounded-lg border-2 px-2 absolute top-5 right-5"></span>
        </figure>
    )
    return (
        [1, 2, 3].map(p => pulse(p))
    );
}

export default Loading;