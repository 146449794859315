import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router";
import { route } from "./Inc/function";
import routes from "./routes";

const AuthRoute = props => {
    const { isAuthenticated, type } = props;
    if (type === "guest" && isAuthenticated) return <Redirect to={route(routes.dashboard)} />
    else if (type === "private" && !isAuthenticated) return <Redirect to={route(routes.login)} />

    return <Route {...props} />
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.account.isAuthenticated
    }
}

export default connect(mapStateToProps)(AuthRoute);