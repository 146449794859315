import clsx from 'clsx';
import React, { useState } from 'react';
import { navBar } from '../../Inc/menu';
import logo from '../../Images/logo.png'
import UserMenu from './UserMenu';

function Header({ account }) {
    const [open, setOpen] = useState(false)
    const toggle = () => {
        setOpen(!open)
    }

    return (
        <nav id="header" className="w-full top-0 bg-white">
            <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-1">
                <div className="pl-4 flex items-center">
                    <a className="toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="/">
                        <img className="h-14" src={logo} alt={process.env.REACT_APP_NAME} />
                    </a>
                </div>
                <div className="block lg:hidden pr-4">
                    <button onClick={toggle}>M</button>
                </div>
                <div className={clsx("w-full flex-grow lg:flex lg:items-center lg:w-auto mt-2 lg:mt-0 lg:bg-transparent text-black p-4 lg:p-0 z-20 nav-content", [!open && "hidden"])}>
                    <UserMenu />
                </div>
            </div>
            <div className={clsx("w-full mx-auto lg:flex justify-center items-center mt-0 py-2 bg-blue-600 nav-content", [!open && "hidden"])}>
                <ul className="list-reset lg:flex">
                    {
                        navBar.map(item => (
                            <li key={item.id} className="mx-2 my-2 bg-green-400 rounded-md">
                                <a className="inline-block font-bold text-white no-underline hover:text-gray-800 py-1 px-4" href={item.link}>{item.title}</a>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </nav>
    );
}

export default Header;