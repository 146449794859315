import { Route, Router } from 'react-router';
import history from './history';
import Routing from './Routing';

function App() {
  //document.title = "Home";
  return (
    <Router history={history}>
      <Route component={Routing} />
    </Router>
  );
}

export default App;
