import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { showEntityItem, updateEntityItem } from '../../Api/Functions';
import Layout from '../Layout'
import NavAccount from './NavAccount';
import { eventValue } from '../../Inc/function';
import Field from '../../components/FormElements/Field';
import AlertBorder from '../../components/Alerts/AlertBorder';
import { changePassword } from '../../Api/Auth';

function Profile({ account }) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [post, setPost] = useState({});
    const [access, setAccess] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const accessProperties = [
        { name: 'email', label: 'Email', field_type: 'email', field_options: [] },
        { name: 'password', label: 'Mot de passe', field_type: 'password', field_options: [] },
        { name: 'password_confirmation', label: 'Confirmez le mot de passe', field_type: 'password', field_options: [] },
    ];

    useEffect(() => {
        setIsLoading(true)
        const entityName = account.isPro ? 'professionnel' : 'particuliers';
        showEntityItem(entityName, account.user.item_id).then(item => {
            item.data && setData(item.data.data)
            setIsLoading(false)
        })
    }, [account]);

    const handleChange = (event, type, name) => {
        const item = eventValue(event, type, name)
        setPost({
            ...post,
            ...item
        });
    }

    const handleChangeAccess = (event, type, name) => {
        const item = eventValue(event, type, name)
        setAccess({
            ...access,
            ...item
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (Object.values(post).length > 0) {
            setSuccess(null);
            setError(null);
            setIsLoading(true)
            const entityName = account.isPro ? 'professionnel' : 'particuliers';
            updateEntityItem(entityName, post, account.user.item_id).then(item => {
                item.error && setError(item.error)
                item.data && setData(item.data.data)
                setIsLoading(false)
            });
        }
    }

    const handleAccessSubmit = (event) => {
        event.preventDefault();
        if (Object.values(access).length > 0) {
            setSuccess(null);
            setError(null);
            setIsLoading(true)
            changePassword(access).then(item => {
                item.error && setError(item.error)
                item.data && setSuccess(item.data)
                setIsLoading(false)
            });
        }
    }

    return (
        <Layout
            meta={{
                title: 'Profil',
                description: ''
            }}
        >
            <NavAccount />
            <div className="flex-grow container mx-auto sm:px-4 pt-6 pb-8">
                <div className='p-4'>
                    <AlertBorder type="success" content={success} />
                    <AlertBorder content={error} />
                </div>
                <div className="flex flex-wrap">
                    <div className="w-full mb-6 lg:mb-0 lg:w-1/2 px-4 flex flex-col">
                        <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
                            <div className="border-b">
                                <div className="flex justify-between px-6 -mb-px">
                                    <h3 className="text-blue-dark py-4 font-normal text-lg">Mes informations</h3>
                                </div>
                            </div>
                            <form className="px-6 pb-6" onSubmit={handleSubmit}>
                                {data?.entity.properties
                                    .sort((a, b) => (a.display_order > b.display_order) ? 1 : -1)
                                    .map((property, index) => (
                                        <Field
                                            key={index}
                                            error={error && (error.message ?? null)}
                                            type={property.field_type}
                                            options={property.field_options}
                                            name={property.name}
                                            label={property.label}
                                            defaultValue={data[property.name]}
                                            placeholder={property.label}
                                            onChange={(e) => handleChange(e, property.field_type, property.name)}
                                        />
                                    ))
                                }
                                <div className='text-center p-5'>
                                    {!isLoading && <button type="submit" className="bg-blue-400 hover:bg-blue-600 text-white border border-blue-400 rounded px-6 py-2">Modifier</button>}
                                    {isLoading && <button type="button" className="bg-blue-400 hover:bg-blue-600 text-white border border-blue-400 rounded px-6 py-2">Loading...</button>}
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 px-4">
                        <div className="bg-white border-t border-b sm:rounded sm:border shadow">
                            <div className="border-b">
                                <div className="flex justify-between px-6 -mb-px">
                                    <h3 className="text-blue-dark py-4 font-normal text-lg">Mon identification</h3>
                                </div>
                            </div>
                            <div>
                                <div className="px-6 pb-6">
                                    <form onSubmit={handleAccessSubmit} className="">
                                        {accessProperties
                                            .map((property, index) => (
                                                <Field
                                                    key={index}
                                                    error={error && (error.message ?? null)}
                                                    type={property.field_type}
                                                    options={property.field_options}
                                                    name={property.name}
                                                    label={property.label}
                                                    placeholder={property.label}
                                                    onChange={(e) => handleChangeAccess(e, property.field_type, property.name)}
                                                />
                                            ))}
                                        <div className='text-center'>
                                            {!isLoading && <button type="submit" className="bg-blue-400 hover:bg-blue-600 text-white border border-blue-400 rounded px-6 py-2">Modifier</button>}
                                            {isLoading && <button type="button" className="bg-blue-400 hover:bg-blue-600 text-white border border-blue-400 rounded px-6 py-2">Loading...</button>}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

const mapStateToProps = (state) => {
    return {
        account: state.account
    }
}

export default connect(mapStateToProps, null)(Profile);
