import { applyMiddleware, createStore } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { verifyToken } from "../Api/Auth";
import reducers from "./reducers";

const store = createStore(
    reducers,
    applyMiddleware(logger, thunk)
);

store.dispatch(verifyToken())

export default store;