import axios from "axios";

const headerParams = {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "X-WDV-AccessToken": `${process.env.REACT_APP_WDV_TOKEN}`,
};

const url = (endpoint) => {
    if(/(http(s?)):\/\//i.test(endpoint)) {
        return endpoint;
    }
    return `${process.env.REACT_APP_WDV_API}/${endpoint}`
}

export const initParams = {
    limit: 10,
    page: 1,
    sort_column: 'created_at',
    sort_direction: 'desc',
}

export const call = async (method, endpoint, params = {}, headers = headerParams) => {
    try {
        const response = await axios({
            method: method,
            url: url(endpoint),
            // data: params,
            headers: headers,
            ...params
        });
        // if (response.status === 200) {
        return { data: response.data }
        // }
    } catch (error) {
        let message = 'Something error'
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data)
            message = error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request)
            message = error.request
        } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error.message)
            message = error.message
        }
        // console.log(error.config)
        return { error: message }

    }
}

export const callDispatch = (method, endpoint, params, actions, headers = headerParams) => {
    return (dispatch) => {
        actions.request && dispatch(actionType(actions.request))
        axios({
            method: method,
            url: url(endpoint),
            params: params,
            headers: headers
        })
        .then((response) => {
            dispatch(actionType(actions.success, response.data))
        })
        .catch((error) => {
            actions.failed && dispatch(actionType(actions.failed, error.response?.data))
        })
    }
}

export const actionType = (action, data = null) => {
    return {
        type: action,
        payload: data
    }
}