import React, { useEffect, useState } from 'react';
import Layout from './Layout'
import { listing } from '../Api/Functions';
import { category, eventValue, route, urlParams, urlSearchParams } from '../Inc/function';
import routes from '../routes';
import Search from './Components/OfferComponents/Search';
import Pagination from './Components/OfferComponents/Pagination';
import OfferItem from './Components/OfferComponents/OfferItem';
import NoData from './Components/OfferComponents/NoData';
import Loading from './Components/OfferComponents/Loading';

function Offers({ match: { params } }) {
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState(urlParams);
    const [autoSubmit, setAutoSubmit] = useState(false);

    const loader = () => {
        if (isLoading) {
            return (
                <Loading/>
            )
        }
    }

    const handleFieldChange = (event, type, name) => {
        const item = eventValue(event, type, name)
        if(item[name]){
            setSearchQuery({
                ...searchQuery,
                ...item
            })
        }else if (searchQuery[name]) {
            delete searchQuery[name]
        }
    }

    const handleChange = (event, submit = false) => {
        if (event.target.value) {
            setSearchQuery({
                ...searchQuery,
                [event.target.name]: event.target.value
            });
        } else if (searchQuery[event.target.name]) {
            delete searchQuery[event.target.name]
        }
        setAutoSubmit(submit)
    }

    const handleSubmitForm = () => {
        urlSearchParams(routes.offers, searchQuery.category || params.category, searchQuery)
    }

    useEffect(() => {
        if (autoSubmit) {
            urlSearchParams(routes.offers, searchQuery.category || params.category, searchQuery)
            setAutoSubmit(false)
        }
    }, [autoSubmit])

    useEffect(() => {
        setLoading(true)
        listing(params.category, searchQuery, { page: urlParams.page ?? 1 }).then(item => {
            item.data && setData(item.data)
            setLoading(false)
        });
    }, [])

    return (
        <Layout
            meta={{
                title: category(urlParams.category || params.category),
                description: 'Annonces description'
            }}
        >
            <Search category={params.category} properties={data && data?.properties} searchQuery={searchQuery} handleSubmitForm={handleSubmitForm} handleChange={handleFieldChange} />

            <div className="container px-6 md:px-1 md:flex my-8 m-auto">
                <div className="md:w-3/4 md:mr-2">
                    <div className="md:flex">
                        <div className="flex flex-grow my-2">
                            <div className="font-medium text-2xl pr-2">Annonces: {category(urlParams.category || params.category)} {`pour ${urlParams.region || 'Toute la France'}`}</div>
                        </div>
                        <div className="md:flex">
                            {/* <button className="hidden md:flex mx-2 bg-white border-blue-500 rounded-lg border-2">
                                <span className="material-icons text-blue-500 m-2">menu</span>
                            </button> */}
                            <div>
                                <div className="flex bg-white border-blue-500 rounded-lg border-2">
                                    <span className="material-icons text-blue-500 my-2 ml-2">filter_list</span>
                                    <select onChange={e => handleChange(e, true)} name="order" defaultValue={urlParams.order} className="w-full border-0 outline-none rounded-lg p-2">
                                        <option value="created_at.desc">Plus récentes</option>
                                        <option value="created_at.asc">Plus anciennes</option>
                                        <option value="prix.asc">Prix croissants</option>
                                        <option value="prix.desc">Prix décroissants</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="md:flex bg-white rounded-md font-medium text-lg my-4 p-6">
                        <div className="pr-8">
                            Resulat: <span className="text-blue-500 font-bold">{data?.meta.total}</span> annonces
                        </div>
                        <div className="pr-8 inline-flex items-center">
                            <input defaultChecked type="checkbox" className="h-5 w-5 mr-2" /> PROS (<span className="text-blue-500 font-bold">0</span>)
                        </div>
                        <div className="pr-8 inline-flex items-center">
                            <input defaultChecked type="checkbox" className="h-5 w-5 mr-2" /> Classiques (<span className="text-blue-500 font-bold">{data?.meta.total}</span>)
                        </div>
                        <div className="pr-8 inline-flex items-center">
                            <input defaultChecked type="checkbox" className="h-5 w-5 mr-2" /> Flash
                        </div>
                    </div>

                    <div>
                        <div className="space-y-4">
                            {
                                data && data.data.map((item, index) => (
                                    <div key={index}>
                                        <a href={route(routes.offerDetails, { category: item.entity.name, id: item.id })}>
                                            <OfferItem data={item} />
                                        </a>
                                    </div>
                                ))
                            }
                            {data && !data.data.length && <NoData/>}
                            {loader()}
                        </div>
                    </div>
                    <Pagination data={data && data.meta} />
                </div>
                <div className="md:w-3/12 md:ml-2 bg-white text-center rounded-lg">Ads</div>
            </div>
        </Layout>
    );
}

export default Offers;