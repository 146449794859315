const routes = {
    home: '/',
    aboutUs: '/qui-sommes-nous',
    offers: '/offers/:category',
    offerDetails: '/offers/:category/:id',
    register: '/inscription/:type',
    login: '/connexion',
    confirm: '/register',
    forgotPassword: '/mot-de-passe-oublie',
    resetPassword: '/reset-password',
    dashboard: '/compte/dashboard',
    profile: '/compte/profile',
    myOffers: '/compte/annonces',
    addOffer: '/compte/annonces/deposer'
}
export default routes
