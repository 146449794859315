import React from 'react'
import protoTypes from 'prop-types'
import clsx from 'clsx'

const inputStyle = "appearance-none shadow-sm rounded-md w-full p-3 border-2 border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
const RenderSelectField = ({
    label,
    placeholder,
    input,
    type,
    name,
    icon,
    options,
    filteredby,
    formvalues,
    className,
    error, //Error form Api Server
    ...custom
}) => {
    let optgroups = options
    if (filteredby) {
        const key = filteredby.name
        optgroups = (formvalues && formvalues[key]) ? options.filter(option => option.optgroupId == formvalues[key]) : []
    }
    return (
        <div className="relative block my-4 space-y-2">
            {label && <label htmlFor={name}>{label}</label>}
            <div className="relative">
                {icon && <span className="material-icons text-blue-500 absolute my-3 mx-2">{icon}</span>}
                <select
                    id={name}
                    type={type}
                    placeholder={placeholder}
                    className={clsx(className ?? inputStyle, { "border-blue-500": !error || !error[name], "border-red-600": error && error[name], "pl-10": icon })}
                    {...input}
                    {...custom}
                >
                    <option value="">{placeholder}</option>
                    {optgroups.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)}
                </select>
                {error && error[name] && <span className="material-icons absolute right-2 my-3 text-red-500">error</span>}
            </div>
            {error && error[name] && <p className="text-red-600 mt-1 text-xs">{error[name][0]}</p>}
        </div>
    )
}

RenderSelectField.protoTypes = {
    input: protoTypes.object.isRequired,
    name: protoTypes.string.isRequired,
    type: protoTypes.string.isRequired,
    label: protoTypes.string,
}

export default RenderSelectField
