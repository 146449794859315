import React from 'react';
import config from '../DatatableConfig';
import DeleteButton from './DeleteButton';

function Body({ entity, service, columns, data }) {
    const bodyStyles = config.style.defaults.body

    const displayFiles = (items) => {
        if (items && Array.isArray(items) && items.length > 0) {
            return (<div className="flex space-x-1">
                {items.map((item, index) => (
                    <img key={index} alt="" src={`${item}&size=30x30xfit`} />
                ))}
            </div>)
        }
        if (items && !Array.isArray(items)) {
            return (<img alt="" className="rounded-md mx-2" src={`${items}&size=40x40xfit`} />)
        }
        return (<span className="material-icons p-2 text-gray-400 bg-gray-200 rounded-md mx-2">image</span>)
    }

    const displayText = (item, attr) => {
        const attrs = attr.split('.')
        let data = item
        attrs.forEach(key => {
            data = data[key]
        })
        return data
    }

    return (
        <tbody>
            {
                data.map(function (item) {
                    let row = columns.filter(property => property.name !== 'name').filter(property => property.options.display).map(function (property, index) {
                        return <td key={index} className={bodyStyles.td}>
                            {
                                ['related', 'select'].includes(property.field_type) ?
                                    property.field_options.find(option => option.value == item[property.name])?.label :
                                    (
                                        property.field_type === 'file' ?
                                            displayFiles(item[property.name]) :
                                            displayText(item, property.name)
                                    )
                            }
                        </td>
                    })
                    return <tr key={item.name} className={bodyStyles.tr}>
                        {row}
                        <td>
                            <DeleteButton
                                entity={item.entity}
                                service={service}
                                itemId={[item.id]}
                            />
                        </td>
                    </tr>
                })
            }
        </tbody>
    );
}

export default Body;