export const categories = [
    {id: 'voitures', label:'Automobile', icon: 'commute'},
    {id: 'motos', label:'Moto/Quad', icon: 'two_wheeler'},
    {id: 'camions', label:'Camion/Bus', icon: 'local_shipping'},
    {id: 'garages', label: 'Garage', icon: 'car_repair'},
    {id: 'industiels', label:'Vécule industriel', icon: 'precision_manufacturing'},
    {id: 'transports', label:'Transport', icon: 'directions_bus'},
    {id: 'formations', label:'Formation routière', icon: 'import_contacts'},
    {id: 'assurances', label: 'Assurance', icon: 'support'},
    {id: 'parkings', label:'Parkings', icon: 'local_parking'},
    {id: 'lavages', label:'Lavage', icon: 'local_car_wash'},
    {id: 'loisirs', label:'Loisirs', icon: 'paragliding'},
    {id: 'sports', label:'Sport mécanique', icon: 'sports_motorsports'}
]