import { authHeader } from "./Auth";
import { call, initParams } from "./Config";
import { URI_ITEMS } from "./uri";

export const listingService = (entity, params = null) => {
    let parameters = initParams
    if (params) {
        parameters = { ...initParams, ...params }
    }
    // let formData = new FormData()
    // formData.append('_method', 'GET')
    // Object.keys(parameters).map(attr =>
    //     formData.append(attr, parameters[attr])
    // )
    const uri = URI_ITEMS.replace('{entityName}', entity.name)
    return call('get', uri, { params: parameters }, authHeader());
}

export const createService = (entity, params) => {
    let formData = new FormData()
    Object.keys(params).map(attr =>
        formData.append(attr, params[attr])
    )
    const uri = URI_ITEMS.replace('{entityName}', entity.name)
    return call('post', uri, { data: formData }, authHeader());
}

export const showService = (entity, id) => {
    const uri = URI_ITEMS.replace('{entityName}', entity.name)
    return call('get', `${uri}/${id}`, {}, authHeader());
}

export const updateService = (entity, params, id) => {
    let formData = new FormData()
    formData.append('_method', 'PATCH')
    Object.keys(params).map(attr =>
        formData.append(attr, params[attr])
    )
    const uri = URI_ITEMS.replace('{entityName}', entity.name)
    return call('post', `${uri}/${id}`, { data: formData }, authHeader());
}

export const deleteService = (entity, ids) => {
    const uri = URI_ITEMS.replace('{entityName}', entity.name)
    return call('delete', uri, { params: { ids: ids } }, authHeader());
}