import { authHeader } from "./Auth";
import { call, initParams } from "./Config";
import { URI_ENTITY, URI_ITEMS } from "./uri";

export const listing = (entityName, filters = {}, params = null) => {
    let parameters = initParams

    if (params) {
        parameters = { ...initParams, ...params }
    }

    if (filters.order) {
        parameters.order = filters.order
        delete filters.order
    }
    parameters.filters = filters;

    const uri = URI_ITEMS.replace('{entityName}', entityName)
    return call('get', uri, { params: parameters });
}

export const showEntity = (entityName) => {
    const uri = URI_ENTITY.replace('{entityName}', entityName)
    return call('get', uri, {})
}

export const showEntityItem = (entityName, id) => {
    const uri = URI_ITEMS.replace('{entityName}', entityName)
    return call('get', `${uri}/${id}`, {});
}

export const updateEntityItem = (entityName, params, id) => {
    let formData = new FormData()
    formData.append('_method', 'PATCH')
    Object.keys(params).map(attr =>
        formData.append(attr, params[attr])
    )
    const uri = URI_ITEMS.replace('{entityName}', entityName)
    return call('post', `${uri}/${id}`, { data: formData }, authHeader());
}