import React from 'react';
import Layout from './Layout'
import Hero from './Components/HomeComponents/Hero'
import SearchAssistant from './Components/HomeComponents/SearchAssistant'
function Home(props) {
    return (
        <Layout
            meta={{
                title: 'Home',
                description: 'Home description'
            }}
        >
            <Hero />
            <SearchAssistant />
        </Layout>
    );
}

export default Home;