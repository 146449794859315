import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { forgotPassword, login, resetPassword } from '../Api/Auth';
import AlertBorder from '../components/Alerts/AlertBorder';
import Field from '../components/FormElements/Field';
import { eventValue, route, urlParms } from '../Inc/function';
import routes from '../routes';
import Layout from './Layout'

function ResetPassword({ resetPassword, account }) {
    const properties = [
        { name: 'email', label: 'Adresse E-mail', field_type: 'email', field_options: [] },
        { name: 'password', label: 'Mot de passe', field_type: 'password', field_options: [] },
        { name: 'password_confirmation', label: 'Retapez le mot de passe', field_type: 'password', field_options: [] },
    ];

    const token = urlParms('token');

    const [isLoading, setLoading] = useState(false);
    const [post, setPost] = useState({token});
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const loader = () => {
        if (isLoading) {
            return (
                <div>Loading...</div>
            )
        }
    }

    const handleChange = (event, type, name) => {
        const item = eventValue(event, type, name)
        setPost({
            ...post,
            ...item
        });
    }

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const response = await resetPassword(post);
        //response?.error && setError(response.error);
        response?.data && setData(response.data);
        console.log(response);
        setLoading(false);
    }

    return (
        <Layout
            meta={{
                title: 'Réinitialisation de votre mot de passe',
                description: 'Réinitialisation de votre mot de passe description'
            }}
        >
            <div className="flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-16 mb-12">
                    <div>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-green-600">Réinitialisation de votre mot de passe</h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Ou{' '}
                            <a href={route(routes.login)} className="font-medium text-blue-600 hover:text-blue-500">
                                connexion
                            </a>
                        </p>
                    </div>
                    <AlertBorder content={account.error} />
                    <p>Vous pouvez modifier votre mot de passe pour des raisons de sécurité, ou le réinitialiser si vous l'avez oublié.</p>
                    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                        <input type="hidden" name="remember" defaultValue="true" />
                        <div className="space-y-4">
                            {properties.map((property, index) => (
                                <Field
                                    required
                                    key={index}
                                    error={account.error && (account.error.message ?? null)}
                                    type={property.field_type}
                                    options={property.field_options}
                                    name={property.name}
                                    placeholder={property.label}
                                    onChange={(e) => handleChange(e, property.field_type, property.name)}
                                />
                            ))}
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <span className="material-icons h-5 w-5 text-green-500 group-hover:text-green-400">lock</span>
                                </span>
                                Réinitialiser
                            </button>
                        </div>
                        {loader()}
                    </form>
                </div>
            </div>
        </Layout>
    );
}

const mapStateToProps = (state) => {
    return {
        account: state.account
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        resetPassword
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
