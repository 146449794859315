export const regions = [
    {id: 'Alsace', label: 'Alsace'},
    {id: 'Aquitaine', label: 'Aquitaine'},
    {id: 'Auvergne', label: 'Auvergne'},
    {id: 'Basse-Normandie', label: 'Basse-Normandie'},
    {id: 'Bourgogne', label: 'Bourgogne'},
    {id: 'Bretagne', label: 'Bretagne'},
    {id: 'Centre', label: 'Centre'},
    {id: 'Champagne-Ardenne', label: 'Champagne-Ardenne'},
    {id: 'Corse', label: 'Corse'},
    {id: 'Franche-Comté', label: 'Franche-Comté'},
    {id: 'Haute-Normandie', label: 'Haute-Normandie'},
    {id: 'Ile-de-France', label: 'Ile-de-France'},
    {id: 'Languedoc-Roussillon', label: 'Languedoc-Roussillon'},
    {id: 'Limousin', label: 'Limousin'},
    {id: 'Lorraine', label: 'Lorraine'},
    {id: 'Midi-Pyrénées', label: 'Midi-Pyrénées'},
    {id: 'Nord-Pas-de-Calais', label: 'Nord-Pas-de-Calais'},
    {id: 'Pays de la Loire', label: 'Pays de la Loire'},
    {id: 'Picardie', label: 'Picardie'},
    {id: 'Poitou-Charentes', label: 'Poitou-Charentes'},
    {id: 'Provence-Alpes-Côte d\'Azur', label: 'Provence-Alpes-Côte d\'Azur'},
    {id: 'Rhône-Alpes', label: 'Rhône-Alpes'},
    {id: 'Guadeloupe', label: 'Guadeloupe'},
    {id: 'Martinique', label: 'Martinique'},
    {id: 'Guyane', label: 'Guyane'},
    {id: 'Réunion', label: 'Réunion'},
]