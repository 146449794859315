import { clearToken, setToken } from "../../Api/Auth";
import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT } from "../../constants/actionTypes";

const initialState = {
    loading: false,
    isAuthenticated: false,
    isPro: false,
    user: null,
    access_token: null,
    error: null,
}

export const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case LOGIN_SUCCESS:
            setToken(action.payload)
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                isPro: sessionStorage.getItem("is_pro") === "1" ? true : false,
                user: action.payload.user,
                access_token: action.payload.token,
                error: null
            }
        case LOGIN_FAILURE:
            return {
                ...state,
                name: null,
                loading: false,
                isAuthenticated: false,
                isPro: false,
                access_token: null,
                error: action.payload
            }
        case LOGOUT:
            clearToken()
            return initialState

        default:
            return state;
    }
}