import React, { useEffect, useState } from 'react';
import { register } from '../Api/Auth';
import AlertBorder from '../components/Alerts/AlertBorder';
import Field from '../components/FormElements/Field';
import Layout from './Layout'
import history from '../history';
import routes from '../routes';
import { eventValue, route } from '../Inc/function';
import { showEntity } from '../Api/Functions';
import clsx from 'clsx';
import axios from "axios";

function Register({ match: { params } }) {
    const authProperties = [
        // { name: 'prenom', label: 'Prénom', field_type: 'string', field_options: [] },
        // { name: 'nom_de_famille', label: 'Nom', field_type: 'string', field_options: [] },
        // { name: 'telephone', label: 'Téléphone', field_type: 'number', field_options: [] },
        // { name: 'ville', label: 'Ville', field_type: 'string', field_options: [] },
        // { name: 'code_postal', label: 'Code postal', field_type: 'number', field_options: [] },
        { name: 'email', label: 'Email', field_type: 'email', field_options: [] },
        { name: 'password', label: 'Mot de passe', field_type: 'password', field_options: [] },
        { name: 'password_confirmation', label: 'Confirmez le mot de passe', field_type: 'password', field_options: [] },
    ];
    const [properties, setProperties] = useState([]);
    const [post, setPost] = useState({});
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        showEntity(params.type).then(item => {
            item.data && setProperties(item.data.data.properties);
        });
    }, []);

    const loader = () => {
        if (isLoading) {
            return (
                <div>Loading...</div>
            )
        }
    }
    const handleChange = async (event, type, name) => {
        const item = eventValue(event, type, name)
        setPost({
            ...post,
            ...item
        });

        if (name === 'code_postal') {
            const response = await axios({
                method: 'get',
                url: `https://vicopo.selfbuild.fr/cherche/${event.target.value}`,

            });
            setCities(response.data.cities);
        }
    }

    const selectCity = (item) => {
        setPost({
            ...{ code_postal: item.code, ville: item.city },
            ...item
        });
        setCities([]);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        register(params.type, post).then(item => {
            item.error && setError(item.error)
            item.data && setData(item.data)
            setLoading(false)
        });
    }

    useEffect(() => {
        data && history.push(route(routes.login, {}), { message: data })
    }, [data])

    return (
        <Layout
            meta={{
                title: 'Inscription',
                description: 'Inscription description'
            }}
        >
            <div className="flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-16 mb-12">
                    <div>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-green-600">Inscription</h2>
                        {params.type === 'particuliers' ? <p className="mt-2 text-center text-sm text-gray-600">
                            Si vous êtes professionel {' '}
                            <a href={route(routes.register, { type: 'professionnel' })} className="font-medium text-blue-600 hover:text-blue-500">
                                cliquez ici
                            </a>
                        </p>
                            :
                            <p className="mt-2 text-center text-sm text-gray-600">
                                Si vous êtes particulier {' '}
                                <a href={route(routes.register, { type: 'particuliers' })} className="font-medium text-blue-600 hover:text-blue-500">
                                    cliquez ici
                                </a>
                            </p>}
                    </div>
                    <AlertBorder content={error} />
                    <div className={clsx("py-8", { "hidden": properties.length > 0 })}></div>
                    <form className={clsx("mt-8 space-y-6", { "hidden": properties.length === 0 })} onSubmit={handleSubmit}>
                        <div>
                            {properties
                                .filter(property => property.field_type !== 'checkbox')
                                .sort((a, b) => (a.display_order > b.display_order) ? 1 : -1)
                                .map((property, index) => (
                                    <div key={index}>
                                        <Field
                                            error={error && (error.message ?? null)}
                                            type={property.field_type}
                                            options={property.field_options}
                                            name={property.name}
                                            // label={property.label}
                                            placeholder={property.label}
                                            value={post[property.name]}
                                            onChange={(e) => handleChange(e, property.field_type, property.name)}
                                        />
                                        {property.name === 'code_postal' && cities.length > 0 && <div className='max-h-60 overflow-auto'>
                                            {cities.map((item, index) => (
                                                <div key={index} className='grid grid-cols-2 gap-4 cursor-pointer' onClick={() => selectCity(item)}>
                                                    <div>{item.code}</div>
                                                    <div>{item.city}</div>
                                                </div>
                                            ))}
                                        </div>}
                                    </div>
                                ))}
                        </div>
                        <hr />
                        <div>
                            {authProperties
                                .map((property, index) => (
                                    <Field
                                        key={index}
                                        error={error && (error.message ?? null)}
                                        type={property.field_type}
                                        options={property.field_options}
                                        name={property.name}
                                        // label={property.label}
                                        placeholder={property.label}
                                        onChange={(e) => handleChange(e, property.field_type, property.name)}
                                    />
                                ))}
                        </div>
                        <div className="flex items-center justify-between">
                            <Field
                                error={error && (error.message['conditions_generales'] ?? null)}
                                type='checkbox'
                                name='conditions_generales'
                                label="J'accepte les <a href='/' class='font-medium text-blue-600 hover:text-blue-500'>Conditions générales</a>"
                                onChange={(e) => handleChange(e, 'checkbox', 'conditions_generales')}
                            />
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <span className="material-icons h-5 w-5 text-green-500 group-hover:text-green-400">how_to_reg</span>
                                </span>
                                Inscription
                            </button>
                            {loader()}
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default Register;