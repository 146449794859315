import React, { useEffect, useState } from 'react';
import { emailVerify, register } from '../Api/Auth';
import AlertBorder from '../components/Alerts/AlertBorder';
import Field from '../components/FormElements/Field';
import Layout from './Layout'
import history from '../history';
import routes from '../routes';
import { eventValue, route, urlParms } from '../Inc/function';
import { showEntity } from '../Api/Functions';
import clsx from 'clsx';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function Confirm({ match: { params }, account, emailVerify }) {
    const id = urlParms('id');
    const confirmationCode = urlParms('confirmation_code');

    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(true);



    useEffect(() => {
        if (id && confirmationCode) {
            const params = {
                id: id,
                confirmation_code: confirmationCode
            };
            emailVerify(params);
            setLoading(false)
        }

    }, [id, confirmationCode]);

    const loader = () => {
        if (isLoading) {
            return (
                <div>Loading...</div>
            )
        }
    }

    return (
        <Layout
            meta={{
                title: 'Confirmation',
                description: 'Confirmation description'
            }}
        >
            <div className="flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-16 mb-12">
                    <div>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-green-600">Confirmation</h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Validation de l'adresse e-mail associée à votre compte
                        </p>
                    </div>
                    <AlertBorder content={account.error} />
                    {/* <div className={clsx("py-8", { "hidden": properties.length > 0 })}></div> */}
                    <div>{loader()}</div>
                </div>
            </div>
        </Layout>
    );
}

const mapStateToProps = (state) => {
    return {
        account: state.account
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        emailVerify
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirm)