import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import Header from './Header';

function index({ meta, children }) {
    return (
        <>
            <Helmet>
                <title>{meta.title} | {process.env.REACT_APP_NAME}</title>
                <meta name="description" content={meta.description} />
            </Helmet>
            <Header />
            {children}
            <Footer />
        </>
    );
}

export default index;