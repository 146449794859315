import React from 'react';
import { NavLink } from 'react-router-dom';
import { route } from '../../Inc/function';
import routes from '../../routes';

function NavAccount(props) {
    const navList = [
        { title: 'Tableau de bord', url: route(routes.dashboard) },
        { title: 'Annonces', url: route(routes.myOffers) },
        { title: 'Profil', url: '/compte/profile' }
    ]
    return (
        <div className="bg-blue-400 md:bg-white md:border-b">
            <div className="container mx-auto px-4">
                <div className="flex mx-4">
                    {
                        navList.map((item, index) => (
                            <div key={index} className="flex -mb-px mr-8">
                                <NavLink
                                    to={item.url}
                                    className="no-underline opacity-50 md:text-gray-700 text-white flex items-center py-4 border-b border-transparent hover:opacity-100 md:hover:border-gray-600"
                                    activeClassName="md:text-blue-600 md:border-blue-500 opacity-100"
                                >
                                    {item.title}
                                </NavLink>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default NavAccount;