import React from 'react';
import { Switch } from 'react-router';
import AuthRoute from './AuthRoute';
import routes from './routes';
import AboutUs from './View/AboutUs';
import Dashboard from './View/Account/Dashboard';
import AddOffer from './View/Account/Offers/AddOffer';
import ListOffers from './View/Account/Offers/ListOffers';
import Profile from './View/Account/Profile';
import Confirm from './View/Confirm';
import ForgotPassword from './View/ForgotPassword';
import Home from './View/Home';
import Login from './View/Login';
import OfferDetails from './View/OfferDetails';
import Offers from './View/Offers';
import Page404 from './View/Page404';
import Register from './View/Register';
import ResetPassword from './View/ResetPassword';

function Routing() {
    return (
        <Switch> 
            <AuthRoute path={routes.offerDetails} component={OfferDetails} />
            <AuthRoute path={routes.offers} component={Offers} />
            
            <AuthRoute path={routes.home} exact={true} component={Home} />
            <AuthRoute path={routes.aboutUs} exact={true} component={AboutUs} />

            <AuthRoute path={routes.register} exact={true} component={Register} type="guest" />
            <AuthRoute path={routes.confirm} exact={true} component={Confirm} type="guest" />
            <AuthRoute path={routes.login} exact={true} component={Login} type="guest" />

            <AuthRoute path={routes.forgotPassword} exact={true} component={ForgotPassword} type="guest" />
            <AuthRoute path={routes.resetPassword} exact={true} component={ResetPassword} type="guest" />

            <AuthRoute path={routes.dashboard} exact={true} component={Dashboard} type="private" />
            <AuthRoute path={routes.profile} exact={true} component={Profile} type="private" />
            <AuthRoute path={routes.myOffers} exact={true} component={ListOffers} type="private" />
            <AuthRoute path={routes.addOffer} exact={true} component={AddOffer} type="private" />
            
            <AuthRoute path='*' exact={true} component={Page404} />
        </Switch>
    );
}

export default Routing;