import React from 'react';
import { category } from '../../../Inc/function';

function OfferItem({ data }) {
    return (
        <figure className="md:flex w-full rounded-xl shadow-md bg-white p-4 md:p-0 relative">
            <picture className="w-full h-60 md:w-64 bg-gray-100 rounded-xl md:rounded-r-none relative items-center justify-center flex">
                {data.image ?
                    <img className="w-full h-56 md:h-full object-cover rounded-xl md:rounded-r-none" src={`${data.image}&size=265x240xfit`} alt={data.name} /> :
                    <span className="material-icons text-gray-300 text-7xl">no_transfer</span>
                }
            </picture>
            <div className="pt-4 md:pb-4 md:px-8 text-center md:text-left flex flex-col">
                <div className="flex-grow">
                    <h2 className="text-lg font-semibold">{data.name}</h2>
                    <p className="break-words mt-3">
                        {data.description}
                    </p>
                    <div className="text-blue-500 font-semibold text-xl my-2">{data.prix} €</div>
                </div>
                <div className="md:flex md:justify-between font-medium text-gray-500 text-left">
                    <div>
                        <div className="inline-flex items-center">
                            <span className="material-icons text-blue-500 mr-2">local_offer</span>
                            {category(data.entity.name)}
                        </div>
                    </div>
                    <div>
                        <div className="inline-flex items-center md:mx-6">
                            <span className="material-icons text-blue-500 mr-2">place</span>
                            {data.region}
                        </div>
                    </div>
                    <div>
                        <div className="inline-flex items-center">
                            <span className="material-icons text-blue-500 mr-2">watch_later</span>
                            {data.created_at}
                        </div>
                    </div>
                </div>
            </div>
            <span className="bg-white border-green-500 text-green-600 rounded-lg border-2 px-2 absolute top-5 right-5 font-semibold opacity-70">PRO</span>
        </figure>
    );
}

export default OfferItem;