import React, { useEffect, useState } from 'react';
import AlertBorder from '../../../components/Alerts/AlertBorder';
import { route } from '../../../Inc/function';
import routes from '../../../routes';
import Layout from '../../Layout'
import NavAccount from '../NavAccount';
import DTable from '../../Components/DTable';
import * as OfferService from '../../../Api/OfferService';

function ListOffers({ location }) {
    const entity = { name: 'annonces', label: 'Annonces' }
    const attributes = [
        { label: 'Avatar', name: 'image', display_order: 0, field_type: 'file', options: { display: true } },
        { label: 'Titre', name: 'titre', display_order: 1, options: { display: true } },
        { label: 'Category', name: 'entity.label', display_order: 2, options: { display: true } },
        { label: 'Images', name: 'images', display_order: 3, field_type: 'file', options: { display: true } },
        { label: 'Prix', name: 'prix', display_order: 4, options: { display: true } },
        // { label: 'Region', name: 'region', display_order: 5, options: { display: true } },
        { label: 'Date', name: 'created_at', display_order: 6, options: { display: true } },
    ]
    const offerService = OfferService
    const [data, setData] = useState({})
    const [selectedItem, setSelectedItem] = useState({})
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null)

    const loader = () => {
        if (isLoading) {
            return (
                <div className='flex fixed z-10 justify-center items-center bg-black bg-opacity-40 w-full h-full top-0 left-0'>
                    <div className='p-10 bg-white rounded-lg'>Loading...</div>
                </div>
            )
        }
    }

    useEffect(() => {
        offerService.listingService(entity).then(item => {
            item.error && setError(item.error)
            item.data && setData(item.data)
            setLoading(false)
        });
    }, [])

    return (
        <Layout
            meta={{
                title: 'List des annonces',
                description: ''
            }}
        >
            <NavAccount />
            <div className="flex-grow container mx-auto sm:px-4 pt-6 pb-8">
                <div className="bg-white border-t border-b sm:border-l sm:border-r sm:rounded shadow mx-4 mb-6">
                    <div className="border-b px-6">
                        <div className="flex justify-between py-4 -mb-px">
                            <h3 className="text-blue-600 text-xl font-semibold">
                                Annonces
                            </h3>
                            <div className="mt-px">
                                <a href={route(routes.addOffer)} className="bg-green-400 hover:bg-green-600 text-white font-bold rounded-md py-2 px-6">Déposer</a>
                            </div>
                        </div>
                    </div>
                    <div className="px-6 py-4">
                        <AlertBorder type="success" content={location && location.state} />
                        <AlertBorder content={error} />
                        {loader()}
                        <DTable
                            entity={entity}
                            properties={attributes}
                            list={data}
                            service={offerService}
                            item={selectedItem}
                            flashMessage={error}
                        />
                    </div>
                </div>

            </div>
        </Layout>
    );
}

export default ListOffers;