import React from 'react';

function Carousel({ images }) {
    if (!images) {
        return (
            <div className="rounded-md w-full h-5/6 items-center justify-center flex bg-gray-100">
                <span className="material-icons text-gray-300 text-9xl">no_transfer</span>
            </div>
        )
    }
    return (
        <div className="space-y-4">
            <div className="flex">
                <img className="rounded-md w-full" alt="" src={`${images[0]}&size=0x450`} />
                {/* {images.map((image, index) => (
                    <div key={index}>
                        <img className="rounded-md w-full" alt="" src={`${image}&size=0x450`} />
                    </div>
                ))} */}
            </div>
            <div className="rounded-md p-2 bg-white">
                <div className="flex justify-center items-center space-x-2">
                    {images.map((image, index) => (
                        <div key={index}>
                            <img className="rounded-md w-full" alt="" src={`${image}&size=60x60xfit`} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Carousel;