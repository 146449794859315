import React, { useState } from 'react';
import { forgotPassword } from '../Api/Auth';
import AlertBorder from '../components/Alerts/AlertBorder';
import Field from '../components/FormElements/Field';
import { eventValue, route } from '../Inc/function';
import routes from '../routes';
import Layout from './Layout'

function ForgotPassword({ location }) {
    const properties = [
        { name: 'email', label: 'Adresse E-mail', field_type: 'email', field_options: [] },
    ]

    const [isLoading, setLoading] = useState(false);
    const [post, setPost] = useState({});
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const loader = () => {
        if (isLoading) {
            return (
                <div>Loading...</div>
            )
        }
    }

    const handleChange = (event, type, name) => {
        const item = eventValue(event, type, name)
        setPost({
            ...post,
            ...item
        });
    }

    const handleSubmit = async (event) => {
        setError(null);
        setData(null);
        setLoading(true);
        event.preventDefault();
        const response = await forgotPassword(post);
        response?.error && setError(response.error);
        response?.data && setData(response.data);
        console.log(response);
        setLoading(false);
    }

    return (
        <Layout
            meta={{
                title: 'Mot de passe oublié',
                description: 'Mot de passe oublié description'
            }}
        >
            <div className="flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-16 mb-12">
                    <div>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-green-600">Mot de passe oublié ?</h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Ou{' '}
                            <a href={route(routes.login)} className="font-medium text-blue-600 hover:text-blue-500">
                                connexion
                            </a>
                        </p>
                    </div>
                    <AlertBorder type="success" content={data} />
                    <AlertBorder content={error} />
                    <p>Vous pouvez modifier votre mot de passe pour des raisons de sécurité, ou le réinitialiser si vous l'avez oublié.</p>
                    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                        <input type="hidden" name="remember" defaultValue="true" />
                        <div className="-space-y-px">
                            {properties.map((property, index) => (
                                <Field
                                    required
                                    key={index}
                                    error={error && (error.message ?? null)}
                                    type={property.field_type}
                                    options={property.field_options}
                                    name={property.name}
                                    placeholder={property.label}
                                    onChange={(e) => handleChange(e, property.field_type, property.name)}
                                />
                            ))}
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <span className="material-icons h-5 w-5 text-green-500 group-hover:text-green-400">lock</span>
                                </span>
                                Envoyer
                            </button>
                        </div>
                        {loader()}
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default ForgotPassword