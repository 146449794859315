export const navTop = [
    {
        id: 'login',
        title: 'Connexion',
        icon: '',
        link: '/connexion'
    },
    {
        id: 'register',
        title: 'Inscription',
        icon: '',
        link: '/inscription/particuliers'
    }
]
export const navBar = [
    {
        id: 'about-us',
        title: 'Qui sommes nous',
        icon: '',
        link: '/qui-sommes-nous'
    },
    {
        id: 'pro-area',
        title: 'Espace Pro',
        icon: '',
        link: '/'
    },
    {
        id: 'shops',
        title: 'Nos boutiques',
        icon: '',
        link: '/'
    },
    {
        id: 'testimonials',
        title: 'On parle de nous',
        icon: '',
        link: '/'
    },
]