import React from 'react';
import { getUriWithParam } from '../../../Inc/function';

function Pagination({ data }) {
    const numberPage = data?.last_page ?? 1
    const currentPage = data?.current_page ?? 1

    const handleClick = (page) => {
        const pageNumber = page !== 1 ? page : null
        window.location.href = getUriWithParam({ page: pageNumber })
    }

    const dirButton = (dir) => {
        const icon = dir === 'next' ? 'navigate_next' : 'navigate_before'
        const page = dir === 'next' ? currentPage + 1 : currentPage - 1
        const defaultStyle = "flex w-8 h-8 mx-1 p-0 rounded-md items-center justify-center relative"
        let action = <button onClick={() => handleClick(page)} className={defaultStyle}><i className="material-icons text-blue-500 text-4xl -ml-px">{icon}</i></button>
        if ((dir === 'previous' && currentPage === data.from) || (dir === 'next' && currentPage === numberPage)) {
            action = <span className={defaultStyle}><i className="material-icons text-gray-300 text-4xl -ml-px">{icon}</i></span>
        }

        return (<li key={dir}>{action}</li>)
    }
    const pageIndexes = () => {
        let iteration = []
        const indexStyle = "flex w-8 h-8 mx-1 p-0 rounded-md items-center justify-center relative border border-solid"
        const defaultIndexStyle = "border-blue-500 bg-white text-blue-500"
        const clickedIndexStyle = "border-blue-500 bg-blue-500 text-white"

        for (let i = 1; i <= numberPage; i++) {
            let index = <button onClick={() => handleClick(i)} className={`${indexStyle} ${defaultIndexStyle}`}>{i}</button>
            if (i === currentPage) {
                index = <span className={`${indexStyle} ${clickedIndexStyle}`}>{i}</span>
            }

            iteration.push(<li key={i}>{index}</li>)

        }
        iteration.unshift(dirButton('previous'))
        iteration.push(dirButton('next'))
        return iteration
    }
    if (data && data.total > 0) {
        return (
            <div className="py-8 md:pb-0">
                <nav className="block">
                    <ul className="flex pl-0 rounded list-none flex-wrap justify-center items-center text-xl">
                        {pageIndexes()}
                    </ul>
                </nav>
            </div>
        )
    }
    return ''
}

export default Pagination;