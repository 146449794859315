import React from 'react';
import Layout from './Layout'

function Page404(props) {
    return (
        <Layout
            meta={{
                title: 'Not found',
                description: ''
            }}
        >
            404
        </Layout>
    );
}

export default Page404;