import React from 'react';
import Field from '../../../components/FormElements/Field';
import { categories } from '../../../Inc/categories';
import { regions } from '../../../Inc/regions';

function Search({ category, properties, searchQuery, handleSubmitForm, handleChange }) {
    const handleSubmit = (event) => {
        event.preventDefault()
        handleSubmitForm()
    }

    const defaultSearch = () => (
        <div className="md:flex">
            <div className="w-full w1/2 my-3">
                <div className="flex border-blue-500 rounded-lg border-2">
                    <span className="material-icons text-blue-500 my-2 ml-2">local_offer</span>
                    <select onChange={e => handleChange(e, 'select', 'category')} name="category" defaultValue={searchQuery.category} className="w-full border-0 outline-none rounded-lg p-2">
                        <option value="">Toutes les annonces</option>
                        {
                            categories.map((category, index) => (
                                <option key={index} value={category.id}>{category.label}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="w-full w1/2 my-3 md:mx-3">
                <div className="flex border-blue-500 rounded-lg border-2">
                    <span className="material-icons text-blue-500 my-2 ml-2">pin_drop</span>
                    <select onChange={e => handleChange(e, 'select', 'region')} name="region" defaultValue={searchQuery.region} className="w-full border-0 outline-none rounded-lg p-2">
                        <option value="">Toute la France</option>
                        {
                            regions.map((region, index) => (
                                <option key={index} value={region.id}>{region.label}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="w-full w1/2 my-3">
                <button type="submit" className="w-full h-full bg-blue-500 text-white font-bold rounded-md py-2 px-6">Recherche</button>
            </div>
        </div>
    )

    const searchByCategory = () => {
        if (category === 'annonces') {
            return defaultSearch()
        }

        if (!properties || !properties.length) {
            return ''
        }

        return (
            <div className="md:flex md:space-x-4">
                {
                    properties
                        .filter(property => property.options.display)
                        .filter(property => property.group_name !== null && property.group_name.toLowerCase().indexOf('filter') !== -1)
                        .sort((a, b) => (a.display_order > b.display_order) ? 1 : -1)
                        .map((property, index) => (
                            <div key={index} className="w-full w1/2 my-1">
                                <Field
                                    key={index}
                                    type={property.field_type}
                                    options={property.field_options}
                                    configs={property.configs}
                                    name={property.name}
                                    icon={property.icon}
                                    placeholder={property.label}
                                    filteredby={property.filtered_by_property_id && properties.find(field => field.id === property.filtered_by_property_id)}
                                    formvalues={searchQuery}
                                    onChange={(e) => handleChange(e, property.field_type, property.name)}
                                    defaultValue={searchQuery[property.name] ?? property.default_value}
                                />
                            </div>
                        ))
                }

                <div className="w-full w1/2 my-5">
                    <button type="submit" className="w-full btn-primary">Recherche</button>
                </div>
            </div>
        )
    }
    return (
        <form className="container bg-white my-8 m-auto p-8 rounded-lg shadow-lg" onSubmit={handleSubmit}>
            <div className="w-full">
                <div className="flex border-blue-500 rounded-lg border-2">
                    <span className="material-icons text-blue-500 my-2 ml-2">search</span>
                    <input onChange={e => handleChange(e, 'string', 'titre')} className="w-full rounded-lg border-0 outline-none p-2" type="text" name="titre" defaultValue={searchQuery.titre} />
                </div>
            </div>
            {searchByCategory()}
        </form>
    );
}

export default Search;