import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login } from '../Api/Auth';
import AlertBorder from '../components/Alerts/AlertBorder';
import Field from '../components/FormElements/Field';
import { eventValue, route } from '../Inc/function';
import routes from '../routes';
import Layout from './Layout'

function Login({ location, login, account }) {
    // sessionStorage.removeItem('is_pro');

    const properties = [
        { name: 'email', label: 'Adresse E-mail', field_type: 'email', field_options: [] },
        { name: 'password', label: 'Mot de passe', field_type: 'password', field_options: [] },
    ]

    const [post, setPost] = useState({});

    const loader = () => {
        if (account.loading) {
            return (
                <div>Loading...</div>
            )
        }
    }

    const handleChange = (event, type, name) => {
        const item = eventValue(event, type, name)
        setPost({
            ...post,
            ...item
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        login(post)
    }

    return (
        <Layout
            meta={{
                title: 'Login',
                description: 'Login description'
            }}
        >
            <div className="flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-16 mb-12">
                    <div>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-green-600">Connexion</h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Ou{' '}
                            <a href={route(routes.register, { type: 'particuliers' })} className="font-medium text-blue-600 hover:text-blue-500">
                                créer votre compte gratuitement
                            </a>
                        </p>
                    </div>
                    <AlertBorder type="success" content={location && location.state} />
                    <AlertBorder content={account.error} />
                    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                        <input type="hidden" name="remember" defaultValue="true" />
                        <div className="-space-y-px">
                            {properties.map((property, index) => (
                                <Field
                                    required
                                    key={index}
                                    error={account.error && (account.error.message ?? null)}
                                    type={property.field_type}
                                    options={property.field_options}
                                    name={property.name}
                                    placeholder={property.label}
                                    onChange={(e) => handleChange(e, property.field_type, property.name)}
                                />
                            ))}
                        </div>

                        <div className="flex items-center justify-between border-2 px-2 rounded">
                            <Field
                                type='checkbox'
                                name='is_pro'
                                label="Je suis professionnel"
                                onChange={(e) => handleChange(e, 'checkbox', 'is_pro')}
                            />
                        </div>

                        <div className="flex items-center justify-between">
                            <Field
                                type='checkbox'
                                name='remember_me'
                                label="Resté connecté"
                                onChange={(e) => handleChange(e, 'checkbox', 'remember_me')}
                            />

                            <div className="text-sm">
                                <a href={route(routes.forgotPassword)} className="font-medium text-blue-600 hover:text-blue-500">
                                    Mot de passe oublié?
                                </a>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <span className="material-icons h-5 w-5 text-green-500 group-hover:text-green-400">lock</span>
                                </span>
                                Connexion
                            </button>
                        </div>
                        {loader()}
                    </form>
                </div>
            </div>
        </Layout>
    );
}

const mapStateToProps = (state) => {
    return {
        account: state.account
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        login
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)