import React, { useEffect, useState } from 'react';
import history from '../../../history';
import { showEntity } from '../../../Api/Functions';
import AlertBorder from '../../../components/Alerts/AlertBorder';
import Field from '../../../components/FormElements/Field';
import { categories } from '../../../Inc/categories';
import { eventValue, route } from '../../../Inc/function';
import Layout from '../../Layout'
import NavAccount from '../NavAccount';
import routes from '../../../routes';
import { createService } from '../../../Api/OfferService';

function AddOffer(props) {
    const [entity, setEntity] = useState(null);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [category, setCategory] = useState(null);
    const [post, setPost] = useState({});
    const [isLoading, setLoading] = useState(false);

    const handleCatChange = (event) => {
        const value = event.target.value
        setError(null)
        if (value) {
            setCategory(value)
            setLoading(true)
            showEntity(value).then(item => {
                item.error && setError(item.error)
                item.data && setEntity(item.data.data)
                setLoading(false)
            })
        } else {
            setCategory(null)
            setEntity(null)
            setPost({})
        }
    }

    const handleChange = (event, property) => {
        const {id, name, field_type} = property
        const item = eventValue(event, field_type, name)
        // Manage filtered properties
        entity.properties.filter(field => field.filtered_by_property_id === id).map(field => {
            item[field.name] = ''
        })
        //
        setPost({
            ...post,
            ...item
        });
    }
    console.log(post)
    const handleSubmit = (event) => {
        if (category) {
            event.preventDefault();
            setError(null)
            setLoading(true)
            createService(entity, post).then(item => {
                item.error && setError(item.error)
                item.data && setData(item.data)
                setLoading(false)
            });
        }
    }

    useEffect(() => {
        data && !error && history.push(route(routes.myOffers, {}), { message: ['Votre annonce a été bien crée'] })
    }, [data, error])

    const loader = () => {
        if (isLoading) {
            return (
                <div className='flex fixed z-10 justify-center items-center bg-black bg-opacity-40 w-full h-full top-0 left-0'>
                    <div className='p-10 bg-white rounded-lg'>Loading...</div>
                </div>
            )
        }
    }

    return (
        <Layout
            meta={{
                title: 'Déposer votre annonce',
                description: ''
            }}
        >
            <NavAccount />
            <div className="flex-grow container mx-auto sm:px-4 pt-6 pb-8">
                <div className="bg-white border-t border-b sm:border-l sm:border-r sm:rounded shadow mx-4 mb-6">
                    <div className="border-b px-6">
                        <div className="flex justify-between -mb-px">
                            <h3 className="text-blue-600 py-4 text-xl font-semibold">
                                Nouvelle annonce
                            </h3>
                        </div>
                    </div>
                    <div className="md:flex">
                        <div className="md:w-1/3 md:p-6 px-6 pt-6 text-lg">
                            Catégorie d'annonce
                        </div>
                        <div className="md:w-2/3 md:p-4 px-6 pb-6">
                            <div className="flex border-blue-500 rounded-lg border-2">
                                <span className="material-icons text-blue-500 my-2 ml-2">local_offer</span>
                                <select name="category" onChange={e => handleCatChange(e)} className="w-full border-0 outline-none rounded-lg p-2">
                                    <option value=""></option>
                                    {
                                        categories.map((category, index) => (
                                            <option key={index} value={category.id}>{category.label}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full px-4">
                    <div className="bg-white border-t border-b sm:rounded sm:border shadow">
                        <div className="border-b">
                            <div className="flex justify-between px-6 -mb-px">
                                <h3 className="text-blue-600 py-4 text-xl font-semibold">
                                    Informations
                                </h3>
                            </div>
                        </div>
                        <div className="px-6 py-4">
                            {loader()}
                            <AlertBorder content={error} />
                            {entity &&
                                <form onSubmit={handleSubmit}>
                                    <Field
                                        error={error && (error.message ?? null)}
                                        type='file'
                                        options={[]}
                                        configs={[{ max: 1, types: "jpg,png,gif,bmp" }]}
                                        name='image'
                                        label='Image'
                                        placeholder='Image'
                                        onChange={(e) => handleChange(e, {name: 'image', field_type: 'file'})}
                                    />
                                    {entity.properties
                                        .sort((a, b) => (a.display_order > b.display_order) ? 1 : -1)
                                        .map((property, index) => (
                                            <Field
                                                key={index}
                                                error={error && (error.message ?? null)}
                                                type={property.field_type}
                                                options={property.field_options}
                                                configs={property.configs}
                                                name={property.name}
                                                icon={property.icon}
                                                label={property.label}
                                                placeholder={property.label}
                                                filteredby={property.filtered_by_property_id && entity.properties.find(field => field.id === property.filtered_by_property_id)}
                                                formvalues={post}
                                                defaultValue={property.default_value}
                                                onChange={(e) => handleChange(e, property)}
                                                value={post[property.name]}
                                            />
                                        ))
                                    }
                                    <button
                                        type="submit"
                                        className="group m-auto relative flex justify-center my-4 py-2 pl-12 pr-5 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                            <span className="material-icons h-5 w-5 text-green-500 group-hover:text-green-400">local_offer</span>
                                        </span>
                                        Envoyer
                                    </button>
                                </form>
                            }
                            {!entity && <div className="text-center">
                                <div className="py-8">
                                    <div className="mb-4">
                                        <span className="material-icons inline-block fill-current text-gray-600 text-8xl" >storefront</span>
                                    </div>
                                    <p className="text-2xl text-grey-darker font-medium mb-4">Ma boutique</p>
                                    <p className="text-grey text-lg max-w-md mx-auto mb-6">Merci de choisir la catégorie pour compléter les informations de votre annonce</p>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>

            </div>
        </Layout>
    );
}

export default AddOffer;