import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { categories } from '../../../Inc/categories';
import { route, urlSearchParams } from '../../../Inc/function';
import { regions } from '../../../Inc/regions';
import routes from '../../../routes';
import FranceRegionsMap from './FranceRegionsMap';

function SearchAssistant(props) {
    const [searchQuery, setSearchQuery] = useState({ category: 'annonces' });

    const handleClick = (name, value) => {
        setSearchQuery({
            ...searchQuery,
            [name]: value
        });
    }

    const handleRegionClick = (value) => {
        handleClick('region', value)
    }

    const handleChange = (event) => {
        if (event.target.value) {
            setSearchQuery({
                ...searchQuery,
                [event.target.name]: event.target.value
            });
        }
    }

    useEffect(() => {
        if(searchQuery.category && searchQuery.region){
            urlSearchParams(routes.offers, searchQuery.category, searchQuery)
        }
    }, [searchQuery])

    console.log(searchQuery)

    return (
        <div className="flex bg-white">
            <div className="container max-w-1xl mx-auto px-4 py-10">
                <h2 className="font-bold uppercase text-center md:text-left">Choisissez une catégorie d'annonces</h2>
                <div className="md:flex my-6">
                    <div className="w-full text-center mb-6">
                        <ul className="flex w-full flex-wrap justify-center md:justify-start text-blue-500">
                            {
                                categories.map((category, index) => (
                                    <button onClick={() => handleClick('category', category.id)} key={index} className={clsx("inline-block relative h-auto w-1/4 m-2 text-center border-green-500 border-4 md:py-6 rounded-3xl hover:bg-green-600 hover:text-white focus:outline-none", {'bg-green-600 text-white': searchQuery.category===category.id})}>
                                        <span className="material-icons w-full m-auto text-5xl md:text-7xl p-2 hover:text-white">{category.icon}</span>
                                        <span className="bottom-2 left-0 w-full font-semibold text-sm">{category.label}</span>
                                    </button>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="w-full text-center md:w-2/3">
                        <button className="btn-primary w-full flex justify-center space-x-3">
                            <span className="material-icons text-white">my_location</span>
                            <span>Utiliser ma localisation</span>
                        </button>
                        <div className="text-center text-gray-700 font-semibold my-2">Ou</div>
                        <div className="md:flex">
                            <div className="w-full">
                                <select onChange={(e) => handleChange(e)} name="region" type="text" className="border-blue-500 w-full h-full rounded-lg border-2 p-3 focus:outline-none">
                                    <option className="text-lg" value="">Sélectionnez votre région</option>
                                    {
                                        regions.map((region, index) => (
                                            <option key={index} value={region.id}>{region.label}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="text-center text-gray-700 font-semibold my-2">Ou</div>
                        <div>
                            <FranceRegionsMap handleClick={handleRegionClick} />
                        </div>
                    </div>
                </div>
                <div className="text-center mt-8">
                    <a href={route(routes.offers, { category: 'annonces' })} className="btn-secondary font-semibold uppercase">Voir toutes les annonces</a>
                </div>
            </div>
        </div>
    );
}

export default SearchAssistant;