import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { showEntityItem } from '../Api/Functions';
import { route } from '../Inc/function';
import routes from '../routes';
import Carousel from './Components/Carousel';
import Layout from './Layout'

function OfferDetails({ match: { params } }) {
    const { category, id } = params
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        showEntityItem(category, id).then(item => {
            item.data && setData(item.data.data)
            item.error && setError(item.error)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        data && showEntityItem(data.user.item.entity.name, data.user.item_id).then(item => {
            item.data && setUser(item.data.data)
        })
    }, [data]);

    console.log(data);

    const phoneAction = (phoneNumber) => {
        console.log(phoneNumber);
        window.location.href = `tel:${phoneNumber}`
    }

    const mailAction = (email) => {
        console.log(email);
        window.location.href = `mailto:${email}`
    }

    const loader = () => {
        if (isLoading) {
            return (
                <div className='text-center p-10'>Loading...</div>
            )
        }
    }

    const detail = () => (
        <>
            <div className="w-full bg-white rounded-md font-medium my-4">
                <div className="flex justify-between items-center p-6 -mb-px">
                    <h1 className="text-2xl font-semibold">
                        {data.name}
                    </h1>
                    <div className="mt-px">
                        <a href={route(routes.offers, { category: category })} className="btn-primary">Retour à la liste d'annonce</a>
                    </div>
                </div>
            </div>
            <div className="md:flex w-full space-x-4">
                <div className="w-1/4 space-y-4">
                    <div className="rounded-md shadow-md bg-white p-5 text-center text-primary font-semibold text-xl">
                        {`${data.prix}€`}
                    </div>
                    <div className="rounded-md shadow-md bg-white p-5 font-semibold text-md space-y-4">
                        <div className="flex justify-between">
                            <h4 className="uppercase">Contact</h4>
                            <span className="bg-white border-green-500 text-green-600 rounded-lg border-2 px-2 font-semibold">PRO</span>
                        </div>
                        <div className="flex space-x-3 items-center">
                            <span className="material-icons p-2 text-gray-400 bg-gray-200 rounded-full">store</span>
                            <span className="text-lg">{user?.name ?? 'Admin'}</span>
                        </div>
                        <div className="flex space-x-1 text-sm items-center">
                            <span className="material-icons text-primary opacity-75">place</span>
                            <span>{user?.ville ?? 'Pas de détail'}</span>
                        </div>
                        {/* <div className="flex space-x-1 text-sm items-center">
                            <span className="material-icons text-primary opacity-75">home</span>
                            <span>www.vroumbi.com</span>
                        </div> */}
                        <button onClick={() => phoneAction(user?.telephone)} className="w-full flex justify-center space-x-4 btn-light">
                            <span className="material-icons opacity-75">phone</span>
                            <span>Téléphone</span>
                        </button>
                        <button onClick={() => mailAction(user?.auth.email)} className="w-full flex justify-center space-x-4 btn-primary">
                            <span className="material-icons opacity-75">mail</span>
                            <span>Email</span>
                        </button>
                        {/* <button className="w-full flex justify-center space-x-4 btn-secondary">
                            <span className="material-icons opacity-75">storefront</span>
                            <span>Visiter notre boutique</span>
                        </button> */}
                    </div>
                    <div className="rounded-md shadow-md bg-white p-5 relative space-y-4">
                        <div className="text-md font-semibold">Description de l'annonce</div>
                        <p className="text-sm text-gray-600">
                            {data.description ?? ''}
                        </p>
                    </div>
                </div>
                <div className="w-1/2 space-y-4">
                    <Carousel images={data.images} />
                    <div>
                        <div className="flex justify-between">
                            <div className="flex justify-center items-center space-x-2">
                                <p>Partager sur</p>
                                <div className=" space-x-4 text-2xl">
                                    <a href="/" className="flex justify-center items-center">
                                        <span className="material-icons text-primary">facebook</span>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <a href="/" className="w-full flex justify-center space-x-1 text-red-600 opacity-50 hover:opacity-100">
                                    <span>Signaler</span>
                                    <span className="material-icons opacity-75">block</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-1/4 space-y-4">
                    <div className="rounded-md shadow-md bg-white p-5 relative space-y-4">
                        <div className="text-md font-semibold">Détails de l'annonce</div>
                        <div className="grid grid-cols-3">
                            {data.entity.properties
                                .filter(property => property.group_name !== null && property.group_name.toLowerCase().indexOf('filter') !== -1)
                                .map((property, index) => (
                                    <div key={index} className="text-center p-2">
                                        <span className="material-icons text-4xl text-primary">{property.icon}</span>
                                        <div className="text-light text-xs font-semibold">{property.label}</div>
                                        <div className="text-ms font-semibold">{data[property.name]}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    return (
        <Layout
            meta={{
                title: data?.name ?? '',
                description: data?.description ?? ''
            }}
        >
            <div className="container px-6 md:px-1 my-8 m-auto">
                {loader()}
                {data && detail()}
            </div>
        </Layout>
    );
}

export default OfferDetails;
