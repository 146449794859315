import JwtDecode from 'jwt-decode'
import cookie from 'react-cookies'
import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT, TOKEN_KEY } from '../constants/actionTypes'
import { call, callDispatch } from './Config'
import { URI_CHANGE_PASSWORD, URI_EMAIL_VERIFY, URI_FORGOT_PASSWORD, URI_LOGIN, URI_LOGOUT, URI_REGISTER, URI_RESET_PASSWORD } from './uri'

export const register = (entityName, params) => {
    if (!['particuliers', 'professionnel'].includes(entityName)) {
        entityName = 'particuliers'
    }
    const uri = URI_REGISTER.replace('{entityName}', entityName)
    return call('post', uri, { params: params });
}

export const login = (params) => {
    const uri = URI_LOGIN
    const actions = {
        request: LOGIN_REQUEST,
        success: LOGIN_SUCCESS,
        failed: LOGIN_FAILURE
    }
    sessionStorage.setItem("is_pro", params['is_pro']);
    return (dispatch) => {
        return dispatch(callDispatch('post', uri, params, actions))
    }
}

export const emailVerify = (params) => {
    const uri = URI_EMAIL_VERIFY;
    const actions = {
        request: LOGIN_REQUEST,
        success: LOGIN_SUCCESS,
        failed: LOGIN_FAILURE
    }
    return (dispatch) => {
        return dispatch(callDispatch('post', uri, params, actions))
    }
}

export const forgotPassword = async (params) => {
    const uri = URI_FORGOT_PASSWORD;
    return await call('post', uri, { params });
}

export const resetPassword = (params) => {
    const uri = URI_RESET_PASSWORD;
    const actions = {
        request: LOGIN_REQUEST,
        success: LOGIN_SUCCESS,
        failed: LOGIN_FAILURE
    }
    return (dispatch) => {
        return dispatch(callDispatch('post', uri, params, actions))
    }
}

export const changePassword = async (params) => {
    const uri = URI_CHANGE_PASSWORD;
    return await call('post', uri, { params }, authHeader());
}

export const logout = () => {
    //TODO connect api to logout from jwt auth
    const uri = URI_LOGOUT;
    sessionStorage.removeItem('is_pro');
    const actions = {
        request: LOGIN_REQUEST,
        success: LOGOUT,
        failed: LOGOUT
    }
    return (dispatch) => {
        return dispatch(callDispatch('post', uri, {}, actions, authHeader()))
    }
    // return (dispatch) => {
    //     clearToken()
    //     return dispatch(actionType(LOGOUT))
    // }
}

export const setToken = (data) => {
    const value = JSON.stringify(data)
    return cookie.save(TOKEN_KEY, value, { path: '/' })
}

export const getToken = () => cookie.load(TOKEN_KEY)

export const clearToken = () => cookie.remove(TOKEN_KEY, { path: '/' })

export const verifyToken = () => {
    return (dispatch) => {
        const data = cookie.load(TOKEN_KEY)
        if (data && JwtDecode(data.token).exp > Date.now() / 1000) {
            dispatch({ type: LOGIN_SUCCESS, payload: data })
        } else {
            clearToken()
        }
    };
}

export const authHeader = () => {
    const data = getToken()
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "X-WDV-AccessToken": `${process.env.REACT_APP_WDV_TOKEN}`,
        'Authorization': `Bearer ${data.token}`
    }
}
