import { Transition } from '@tailwindui/react';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navTop } from '../../Inc/menu';
import { logout } from '../../Api/Auth';
import { route } from '../../Inc/function';
import routes from '../../routes';
import { showEntityItem } from '../../Api/Functions';

function UserMenu({ account, logout }) {
    const [open, setOpen] = useState(false)
    const container = useRef(null);
    const [user, setUser] = useState(null);

    const toggle = () => {
        setOpen(!open)
    }

    const handleLogout = () => {
        setOpen(false)
        logout()
    }

    useEffect(() => {
        const entityName = account.isPro ? 'professionnel' : 'particuliers';
        account.isAuthenticated && showEntityItem(entityName, account.user.item_id).then(item => {
            item.data && setUser(item.data.data)
        })
    }, [account]);
    
    useEffect(() => {
        function handleOutsideClick(event) {
            if (container.current && !container.current.contains(event.target)) {
                if (!open) return;
                setOpen(false);
            }
        }

        window.addEventListener("click", handleOutsideClick);
        return () => window.removeEventListener("click", handleOutsideClick);
    }, [open, container]);

    if (account.isAuthenticated) {
        return (
            <div className="flex justify-center lg:justify-end flex-1 items-center">
                <div ref={container} className="relative">
                    <button onClick={toggle} className="flex items-center focus:outline-none">
                        <span className="mx-3">
                            {account.user.image ? <img
                                className="h-9 w-9 rounded-full"
                                src={account.user.image}
                                alt={account.user.name}
                            />:<span className="material-icons p-2 text-gray-400 bg-gray-200 rounded-full">person</span>}
                        </span>
                        {user?.name}
                    </button>
                    <Transition
                        show={open}
                        enter="transition-opacity duration-75"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="absolute right-0 rounded-md shadow-md bg-white">
                            <div className="py-1">
                                <a href={route(routes.profile)} className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none">
                                    Profil
                                </a>
                                <a href={route(routes.dashboard)} className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none">
                                    Mon compte
                                </a>
                                <button onClick={() => handleLogout()} className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none">
                                    Déconnexion
                                </button>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        )
    }
    return (
        <ul className="list-reset flex justify-center lg:justify-end flex-1 items-center">
            {
                !account.isAuthenticated && navTop.map(item => (
                    <li key={item.id} className="mr-3">
                        <a className="inline-block py-2 px-4 text-black no-underline" href={item.link}>{item.title}</a>
                    </li>
                ))
            }
        </ul>
    );
}

const mapStateToProps = (state) => {
    return {
        account: state.account
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        logout
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
