import React, { useState } from 'react'
import protoTypes from 'prop-types'
import clsx from 'clsx'

const inputStyle = "appearance-none shadow-sm rounded-md w-full p-3 border-2 border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
const RenderFileField = ({
    defaultValue,
    meta: omitMeta,
    configs,
    mimeType,
    label,
    name,
    className,
    error, //Error form Api Server
    ...props
}) => {
    const [data, setData] = useState(defaultValue)
    const [files, setFiles] = useState([])
    const onChange = (e) => {
        setFiles(e.target.files)
        props.onChange(e)
    }

    const [srcId, setSrcId] = React.useState(null);
    const handleClick = (index) => {
        setSrcId(index + 1);
    }

    const displayFiles = ($items) => {
        if ($items && $items.length > 0) {
            const imgList = $items.map((item, index) => (
                <button onClick={() => handleClick(index)} key={index}>
                    <img alt="" src={`${item}&size=50x50xfit`} />
                </button>
            ))
            const handleClose = () => {
                setSrcId(null);
            }
            const handleDelete = (key) => {
                setData(data.filter((file, index) => index !== key))
                setSrcId(null);
            }
            const dialog = (
                <div onClose={handleClose} className="absolute" aria-labelledby="simple-dialog-title" open={Boolean(srcId)}>
                    {srcId && <a href={`${data[srcId - 1]}&download`}><img alt="" src={data[srcId - 1]} /></a>}
                    <button onClick={() => handleDelete(srcId - 1)} className="absolute bg-white bottom-0 w-full opacity-0 hover:opacity-75">Supprimer</button>
                </div>
            )

            return (<div className="flex">{imgList}{dialog}</div>)
        }
        return null
    }

    return (
        <div className="relative block my-4 space-y-2">
            <label className="relative transform-none" htmlFor={name}>{(typeof label === 'string') ? label : ''}</label>
            {displayFiles(data)}
            <div className="w-full">
                <div className={clsx("", { "border-red-600": error && error[name] })}>
                    <input
                        id={name}
                        type="file"
                        multiple={configs?.file?.max > 1}
                        accept={configs?.file?.types.split(',').map(type => `.${type}`).join(',').replace(/ /g, '')}
                        {...props}
                        onChange={onChange}
                        className={clsx(className ?? inputStyle, { "border-blue-500": !error || !error[name], "border-red-600": error && error[name] })}
                    />
                </div>

                {error && error[name] && <p className="text-red-600 mt-1 text-xs">{error[name][0]}</p>}
                <ul className="list-decimal ml-5 mt-2 font-semibold text-gray-700">
                    {Object.values(files).map((file, index) => (
                        <li key={index}>
                            {file.name}
                            {error && error[`${name}.${index}`] && <p className="text-red-600 mt-1 text-xs">{error[`${name}.${index}`][0]}</p>}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}


RenderFileField.protoTypes = {
    input: protoTypes.object.isRequired,
    name: protoTypes.string.isRequired,
    label: protoTypes.string,
    meta: protoTypes.object
}

export default RenderFileField
