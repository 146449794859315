import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from '../../Api/Auth';
import { showEntityItem } from '../../Api/Functions';
import Layout from '../Layout'
import NavAccount from './NavAccount';

function Dashboard({ account, logout }) {
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        // setLoading(true)
        const entity = account.isPro ? 'professionnel' : 'particuliers';
        showEntityItem(entity, account.user.item_id).then(e => {
            if ('error' in e) logout()
            else setLoading(false)
        })

    }, [account]);

    if (isLoading) {
        return (
            <div>Loading...</div>
        )
    }

    return (
        <Layout
            meta={{
                title: 'Dashboard',
                description: ''
            }}
        >
            <NavAccount />
            <div className="flex-grow container mx-auto sm:px-4 pt-6 pb-8">
                <div className="bg-white border-t border-b sm:border-l sm:border-r sm:rounded shadow mx-4 mb-6">
                    <div className="border-b px-6">
                        <div className="flex justify-between -mb-px">
                            <div className="text-gray-600 py-4 text-lg">
                                Vue globale
                            </div>
                            <div className="flex text-sm">
                                <button type="button" className="appearance-none py-4 text-gray-500 border-b border-transparent hover:border-gray-500 mr-3">
                                    1D
                                </button>
                                <button type="button" className="appearance-none py-4 text-gray-500 border-b border-transparent hover:border-gray-500 mr-3">
                                    1W
                                </button>
                                <button type="button" className="appearance-none py-4 text-gray-500 border-b border-transparent hover:border-gray-500 mr-3">
                                    1M
                                </button>
                                <button type="button" className="appearance-none py-4 text-gray-500 border-b border-transparent hover:border-gray-500 mr-3">
                                    1Y
                                </button>
                                <button type="button" className="appearance-none py-4 text-blue-500 border-b border-blue-500 mr-3">
                                    ALL
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center px-6 lg:hidden">
                        <div className="flex-grow flex-no-shrink py-6">
                            <div className="text-gray-700 mb-2">
                                <span className="text-5xl">21,404</span>
                                <span className="text-3xl align-top">.74</span>
                                <span className="text-3xl align-top">€</span>
                            </div>
                            <div className="text-green-light text-md">
                                Valeur de vos annonces
                            </div>
                        </div>
                        <div className="flex-shrink w-32 inline-block relative">
                            <select className="block appearance-none w-full bg-white border border-grey-light px-4 py-2 pr-8 rounded">
                                <option>Valeur</option>
                                <option>Total</option>
                                <option>Visibilité</option>
                            </select>
                            <div className="pointer-events-none absolute top-3 right-0 flex items-center px-2 text-gray-600">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>
                    <div className="hidden lg:flex">
                        <div className="w-1/3 text-center py-8">
                            <div className="border-r">
                                <div className="text-gray-700 mb-2">
                                    <span className="text-5xl">21,404</span>
                                    <span className="text-3xl align-top">.74</span>
                                    <span className="text-3xl align-top">€</span>
                                </div>
                                <div className="text-sm uppercase text-grey tracking-wide">
                                    Valeur de vos annonces
                                </div>
                            </div>
                        </div>
                        <div className="w-1/3 text-center py-8">
                            <div className="border-r">
                                <div className="text-gray-700 mb-2">
                                    <span className="text-5xl">12,998</span>
                                </div>
                                <div className="text-sm uppercase text-grey tracking-wide">
                                    Nombre de vos annonces
                                </div>
                            </div>
                        </div>
                        <div className="w-1/3 text-center py-8">
                            <div>
                                <div className="text-gray-700 mb-2">
                                    <span className="text-3xl align-top"><span className="text-green-500 align-top">+</span></span>
                                    <span className="text-5xl">154.47</span>
                                    <span className="text-3xl align-top">%</span>
                                </div>
                                <div className="text-sm uppercase text-grey tracking-wide">
                                    Visibilité (%)
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap">
                    <div className="w-full mb-6 lg:mb-0 lg:w-1/2 px-4 flex flex-col">
                        <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
                            <div className="border-b">
                                <div className="flex justify-between px-6 -mb-px">
                                    <h3 className="text-blue-dark py-4 font-normal text-lg">Top recherchées</h3>
                                    <div className="flex">
                                        <button type="button" className="appearance-none py-4 text-blue-dark border-b border-blue-dark mr-3">
                                            Voir +
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex px-6 py-3 text-gray-600 items-center border-b -mx-4">
                                <div className="w-2/5 xl:w-1/4 px-4 flex items-center">
                                    <div className="rounded-full bg-orange inline-flex mr-3">
                                        <img src="" alt="" className="w-12 h-12" />
                                    </div>
                                    <span className="text-lg">Annonce</span>
                                </div>
                                <div className="hidden md:flex lg:hidden xl:flex w-1/4 px-4 items-center">
                                    <div className="bg-orange h-2 rounded-full flex-grow mr-2"></div>
                                    100%
                                </div>
                                <div className="flex w-3/5 md:w/12">
                                    <div className="w-1/2 px-4">
                                        <div className="text-right">
                                            0.0010
                                        </div>
                                    </div>
                                    <div className="w-1/2 px-4">
                                        <div className="text-right text-grey">
                                            21.28
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 px-4">
                        <div className="bg-white border-t border-b sm:rounded sm:border shadow">
                            <div className="border-b">
                                <div className="flex justify-between px-6 -mb-px">
                                    <h3 className="text-blue-dark py-4 font-normal text-lg">Recent Activity</h3>
                                </div>
                            </div>
                            <div>
                                <div className="text-center px-6 py-4">
                                    <div className="py-8">
                                        <div className="mb-4">
                                            <span className="material-icons inline-block fill-current text-gray-600 text-8xl" >storefront</span>
                                        </div>
                                        <p className="text-2xl text-grey-darker font-medium mb-4">No buys or sells yet</p>
                                        <p className="text-grey max-w-xs mx-auto mb-6">You've successfully linked a payment method and can start buying digital currency.</p>
                                        <div>
                                            <button type="button" className="bg-blue-400 hover:bg-blue-600 text-white border border-blue-400 rounded px-6 py-2">Buy now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

const mapStateToProps = (state) => {
    return {
        account: state.account
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        logout
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
