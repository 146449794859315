import React from 'react';

function Header({ columns }) {
    return (
        <thead>
            <tr>
                {columns.filter(property => property.options.display).map((property, index) => {
                    return <th
                        key={property.name}
                    >
                        {property.label}
                    </th>
                })}
                <th></th>
            </tr>
        </thead>
    );
}

export default Header;