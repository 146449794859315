import React, { useState } from 'react';
import Body from './components/Body';
import Header from './components/Header';
import config from './DatatableConfig';

function index({ entity, properties, service, list, item, flashMessage }) {
    const style = config.style.defaults
    const columns = properties.sort((a, b) => (a.display_order > b.display_order) ? 1 : -1)
    return (
        <>
            <h3>
                {entity.label}
            </h3>
            <table className={style.table}>
                <Header
                    columns={columns}
                />
                <Body
                    entity={entity}
                    service={service}
                    columns={columns}
                    data={list['data'] ?? []}
                />
            </table>
        </>
    );
}

export default index;