import React from 'react'
import * as FormElements from '.'

function Field({type, ...others}) {
    const Component = FormElements[type]
    return (
        <Component
            type={type}
            {...others}
        />
    )
}

export default Field
