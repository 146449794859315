import React from 'react';
import carImg from '../../../Images/car.jpg';
import { route } from '../../../Inc/function';
import routes from '../../../routes';
function Hero() {
    return (
        <div className="w-full">
            <div className="h-96 w-full">
                <img className="object-cover w-full h-full" alt="car" src={carImg} />
            </div>
            <div className="bg-blue-500 text-white text-center py-10 px-4">
                <p className="mb-10">
                    text text text
                </p>
                <a href={route(routes.addOffer)} className="btn-secondary uppercase font-semibold">Déposer votre annonce</a>
            </div>
        </div>
    );
}

export default Hero;