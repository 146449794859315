import { categories } from "./categories"

export const category = (entityName) => {
    const category = categories.filter(category => category.id === entityName)
    return category.length > 0 ? category[0]['label'] : 'Offres'
}

export const urlParms = (param) => {
    const queryString = window.location.search
    const params = new URLSearchParams(queryString)
    return params.get(param)
}

export const urlParams = window.location.search ? JSON.parse('{"' + decodeURI(window.location.search).replace(/\?/g, '').replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}') : {}

export const route = (pattern, params = {}) => {
    for (const [key, value] of Object.entries(params)) {
        pattern = pattern.replace(':' + key, value)
    }
    return pattern
}

export const urlSearchParams = (routeName, categoryName, searchQuery = {}) => {
    const routeOffers = route(routeName, { category: categoryName })
    delete searchQuery.category
    const searchQueryString = new URLSearchParams(searchQuery).toString()
    window.location.href = `${routeOffers}${searchQueryString && `?${searchQueryString}`}`
}

export const getUriWithParam = (params) => {
    const Url = new URL(window.location.href);
    const urlParams = new URLSearchParams(Url.search);
    for (const key in params) {
        if (params[key] === null) {
            urlParams.delete(key)
        } else if (params[key] !== undefined) {
            urlParams.set(key, params[key]);
        }
    }
    Url.search = urlParams.toString();
    return Url.toString();
}

export const eventValue = (event, type, name) => {
    let value = ''
    switch (type) {
        case 'checkbox':
            value = event.target.checked ? 1 : 0
            break;
        case 'date':
            value = event
            break;
        case 'datetime':
            value = event
            break;
        case 'file':
            value = event?.target?.files.length > 0 ? event.target.files : ''
            break;
        default:
            value = event.target.value
            break;
    }

    let item = { [name]: value }
    if (type === 'file' && value) {
        item = []
        if(name === 'image'){
            item[name] = value[0]
        }else{
            Object.values(value).map((file, index) => (
                item[`${name}[${index}]`] = file
            ))
        }
    }

    return item
}