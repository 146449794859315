import React from 'react';

function Footer(props) {
    return (
        <div className="flex bg-blue-500 text-white">
            <div className="container m-auto py-12 px-4">
                <div className="text-center">
                    <a href="/" className="no-underline hover:no-underline font-bold text-2xl lg:text-4xl">VROUMBI</a>
                </div>
                <div className="flex text-center font-blod text-md py-8 leading-10">
                    <div className="flex-1">
                        <ul>
                            <li>Contact</li>
                            <li>Nos partenaire</li>
                            <li>...</li>
                        </ul>
                    </div>
                    <div className="flex-1">
                        <ul>
                            <li>Conditions générales</li>
                            <li>Mentions légales</li>
                            <li>...</li>
                        </ul>
                    </div>
                    <div className="flex-1">
                        <ul>
                            <li>Le groupe</li>
                            <li>Aide?</li>
                            <li>...</li>
                        </ul>
                    </div>
                </div>
                <hr className="border-2 border-white opacity-50 my-0 py-0" />
                <div className="flex justify-between my-5">
                    <div>{`CopyRight ${new Date().getFullYear()} VroumBi`}</div>
                    <div>
                        <ul className="flex">
                            <li className="ml-2">Facebook</li>
                            <li className="ml-2">Instagram</li>
                            <li className="ml-2">Twitter</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;