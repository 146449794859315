import React from 'react'
import protoTypes from 'prop-types'
import clsx from 'clsx'
import parse from 'html-react-parser'

const inputStyle = "h-4 w-4 focus:ring-blue-500 rounded"
const RenderCheckboxField = ({
    label,
    placeholder,
    input,
    name,
    className,
    error, //Error form Api Server
    ...custom
}) => (
    <div className="my-2">
        <div className="flex items-center">
            <input
                id={name}
                name={name}
                className={clsx(className ?? inputStyle, { "border-red-600": error && error[name] })}
                onChange={custom.onChange}
                defaultChecked={Boolean(Number(custom.defaultValue))}
                {...input}
                {...custom}
            />
            <label htmlFor={name} className="ml-2 block text-sm text-gray-900">
                {label}
            </label>
        </div>
        {error && error[name] && <p className="text-red-600 mt-1 text-xs">{error[name][0]}</p>}
    </div>
)

RenderCheckboxField.protoTypes = {
    input: protoTypes.object.isRequired,
    name: protoTypes.string.isRequired,
    type: protoTypes.string.isRequired,
    label: protoTypes.string,
}

export default RenderCheckboxField
