import React from 'react';

function AlertBorder({ type = 'error', content }) {
    if (!content) {
        return ''
    }

    let style = "shadow-md bg-red-50 border-l-4 border-red-500 text-red-700 p-4"

    if (type === 'success') {
        style = "shadow-md bg-green-50 border-l-4 border-green-500 text-green-700 p-4"
    }

    return (
        <div className={style} role="alert">
            <p className="font-bold">{type === 'success' ? 'Succès' : 'Erreurs'}</p>
            {
                Object.values(content.message).map((message, index) => (
                    <p key={index}>{message}</p>
                ))
            }
        </div>
    );
}

export default AlertBorder;