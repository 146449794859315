import React from 'react';
import Layout from './Layout'

function AboutUs(props) {
    return (
        <Layout
            meta={{
                title: 'About us',
                description: 'About us description'
            }}
        >
            About us
        </Layout>
    );
}

export default AboutUs;