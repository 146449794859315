import React from 'react';
import { route } from '../../../Inc/function';
import routes from '../../../routes';

function NoData(props) {
    return (
        <figure className="md:flex w-full rounded-xl shadow-md bg-white p-4 relative">
            <div className="w-full py-10 space-y-6 bg-gray-100 rounded-xl text-center">
                <div className="md:flex text-center relative items-center justify-center">
                    <div className="material-icons text-gray-300 text-9xl">no_transfer</div>
                    <h2 className="font-semibold text-xl p-2">Désolé, nous n'avons rien trouvé !</h2>
                </div>
                <div>
                    <a href={route(routes.offers, { category: 'annonces' })} className="btn-secondary font-semibold uppercase">Voir toutes les annonces</a>
                </div>
            </div>
        </figure>
    );
}

export default NoData;